'use client'
import { searchResultHit } from "../../../lib/search/server-actions.server";
import Link from "next/link";
import { usePathname } from "next/navigation";

export const SURelatedResource = ({href, highlight, ...props}: searchResultHit) => {
  const pathname = usePathname()

  // const summaryFieldName = [sourceName, objName, 'summary'].join('___') + '.en';
  const Topic = highlight['Topic'] || undefined

  // Hack to make production links relative.
  const rel = href.startsWith('https://docs.acquia.com/') ? href.replace('https://docs.acquia.com', '') : href

  const isActive = rel == pathname

  if (isActive) {
    return <></>
  }
  
  return (
    <Link className="hover:bg-gray-500 p-2 font-bold rounded-lg block" href={rel}>{highlight.TitleToDisplayString}</Link>
  )
}