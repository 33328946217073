'use client'
import { StatusMessage } from "@/components/wysiwyg/StatusMessage"
import { useContentFromRoute } from "@/contexts/ContentRouteProvider"
import { searchParamsVersionedContent } from "@/lib/searchParams"
import { DrupalTaxonomyTermProduct } from "@/types"
import { useQueryStates } from "nuqs"

export const VersionStatusMessage = () => {
  const { resource } = useContentFromRoute()
  const [params, setParams] = useQueryStates(searchParamsVersionedContent)
  const product:DrupalTaxonomyTermProduct|undefined = resource?.field_product ?? resource?.field_products[0]

  if (!resource || !product) {
    return <></>
  }
  const versionLatest = product.field_current_version?.name

  // Version support not set.
  if (!versionLatest) {
    return <></>
  }

  if (params.v === null || params.v === 'undefined') {
    return <></>
  }
  
  return <>
    { versionLatest !== undefined && params.v !== versionLatest && (
      <StatusMessage type="important">
        <p>{`You're viewing version ${params.v} of this page.`}&nbsp;
          <button onClick={() => setParams({...params, v: versionLatest}, { shallow: false })} className="text-blue-600 underline">View latest ({versionLatest}) documentation</button>.
        </p> 
      </StatusMessage>
    )}
  </>
}