'use client'
import { useRedirectProvider } from "@/contexts/RedirectProvider";
import { permanentRedirect } from "next/navigation";
import { ReactNode, useEffect } from "react";

export const LateRedirectHandler = ({children}: {
  children: ReactNode
}) => {
  const redirect = useRedirectProvider()

  useEffect(() => {
    const hash = window.location.hash;
    // Check if redirect is present and append the section hash at the end of redirect.
    if (redirect !== undefined) {
      permanentRedirect(redirect.to+hash)
    }
  }, [redirect])
 
  return <>{children}</>
}