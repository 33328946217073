'use client'
import { usePathname } from "next/navigation";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";

export type TocContext = {
  enabled: boolean
  setEnabled: Dispatch<SetStateAction<boolean>>
}

const ToC = createContext<TocContext>({
  enabled: false,
  setEnabled<A = boolean>(value: A) {

  }
})

export const useToc = () => useContext(ToC);

export const TocProvider = ({children}: {
  children: ReactNode
}) => {
  const [enabled, setEnabled] = useState<boolean>(false)
  return <ToC.Provider value={{
    enabled,
    setEnabled
  }}>
    {children}
  </ToC.Provider>
}

export const TocMarker = ({enabled}: {enabled: boolean}) => {
  const {setEnabled} = useToc();
  useEffect(() => {
    setEnabled(enabled)
  }, [enabled, setEnabled])
  return <></>
}