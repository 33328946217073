'use client'
import Link from "next/link"
import { ProductLogo } from "../assets/ProductLogo"
import { DrupalTaxonomyTermProduct } from "@/types"
import { DrupalMenuLinkContent } from "next-drupal"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { Bars3Icon, ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { Fragment, useEffect, useRef, useState } from "react"
import { formatLinkFieldUrl } from "@/lib/utils"
import classNames from "classnames"
import { usePathname } from "next/navigation"
import { getActiveTrail } from "@/lib/drupal/util"
import useScreens from "../hooks/useScreens"
import { useFeatureFlags } from "@/contexts/FeatureFlagsProvider"
import { useSidebarDraw } from "@/contexts/SidebarDrawProvider"
import { useSearch } from "@/contexts/SearchProvider"

export const ProductNavigationClient = ({ product, menu_links }: {
  product: DrupalTaxonomyTermProduct
  menu_links: DrupalMenuLinkContent[]
}) => {
  const stickyRef = useRef(null)
  const markerRef = useRef(null)
  const observer = useRef<IntersectionObserver>()
  const [isSticky, setSticky] = useState(false)
  const pathname = usePathname()
  const activeTrail = getActiveTrail(menu_links, pathname)
  const screen = useScreens()

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]: IntersectionObserverEntry[]) => {
      if (stickyRef.current) {
        setSticky(entry.intersectionRatio < 1)
      }
    }, {
      rootMargin: "0px 0px 0px 0px",
      threshold: [1],
    })

    if (markerRef.current) {
      observer.current.observe(markerRef.current)
      return () => observer.current?.disconnect()
    }
  }, [stickyRef, markerRef])

  return <>
    <nav ref={stickyRef} aria-label={product.name} className={classNames(
      "sticky top-0 bg-white z-10 whitespace-nowrap",
      isSticky ? 'shadow-lg' : 'shadow'
    )} id="productNavigation">
      <div className={classNames(
        "container mx-auto flex flex-col md:flex-row gap-x-6 md:items-center px-5 text-navy-600 font-medium text-lg transition-all",
        isSticky ? 'py-3 md:py-0' : 'py-3'
      )}>
        <Disclosure>
          <h2 className={classNames("flex gap-2", isSticky ? "pb-2 md:pb-0" : "")}>
            <Link className="flex gap-x-2 items-center" href={formatLinkFieldUrl(product.field_landing_page)}>
              <ProductLogo aria-hidden="true" name={product.field_product_logo} className="w-8" />{product.name}
            </Link>
            <div className="flex-grow">&nbsp;
            </div>
            <Disclosure.Button
              className={classNames(
                "flex items-center gap-2 print:hidden lg:top-1 lg:absolute bg-gray-400 rounded-md p-2 hover:bg-navy-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white",
                "md:hidden"
              )}>
              {({ open }) => (
                open ?
                  <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  :
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </Disclosure.Button>

          </h2>
          <Disclosure.Panel static={screen?.md}>
            <ul className="flex flex-col flex-wrap md:flex-row gap-x-6 md:items-center" role="menu" aria-orientation="horizontal">
              {menu_links.map(link =>
                link.items?.length && link.expanded ?
                  (
                    <li key={link.id} role="none">
                      <DropDownMenu
                        label={link.title}
                        items={link.items}
                        href={link.url}
                        isActive={
                          activeTrail && activeTrail[0].id == link.id
                        }
                      />
                    </li>
                  ) : (
                    <li key={link.id} role="none">
                      <Link role="menuitem" className={classNames(
                        isSticky ? 'border-b-4' : 'border-b-2',
                        "flex py-3  hover:border-navy-600 focus:border-blue-800",
                        activeTrail ? (activeTrail[0].id == link.id ? 'border-navy-600' : 'border-transparent') : 'border-transparent'
                      )} href={link.url}>{link.title}</Link>
                    </li>
                  )
              )}
              <li role="none">
                <SearchButton isSticky={isSticky} product={product} />
              </li>
            </ul>
          </Disclosure.Panel>
        </Disclosure>
      </div>
    </nav>
    <div ref={markerRef} />
  </>
}

function SearchButton({ className, product, isSticky }: {
  className?: string
  product: DrupalTaxonomyTermProduct
  isSticky: boolean
}) {
  const { search, draw, close, openDraw } = useSearch()
  const { flags } = useFeatureFlags();

  function click() {
    if (flags.FLAG_AI_CHAT_ENABLED) {
      draw === 'chat' ? close() : openDraw('chat')
    }
    else {
      draw === 'search' ? close() : [openDraw('search'), search('', { products: [product.name] })]
    }
  }

  return <button
    role="menuitem"
    className={classNames(
      isSticky ? 'border-b-4' : 'border-b-2',
      "flex py-3  hover:border-navy-600 focus:border-blue-800",
      draw !== null ? 'border-navy-600' : 'border-transparent'
    )}
    onClick={(e) => {
      click()
      e.currentTarget.blur()
    }}
  >
    Help
  </button>
}

function DropDownMenu({ label, items, isActive, href }: {
  label: string,
  items: DrupalMenuLinkContent[],
  href?: string
  isActive: boolean
}) {
  return <Menu as="div" className="relative">
    {({ open }) => (
      <>
        <div>
          <Menu.Button
            className={classNames(
              "flex items-center py-3 border-b-2 hover:border-navy-600 focus:border-blue-800",
              open || isActive ? 'border-navy-600' : 'border-transparent',
            )} role="menuitem">
            {label}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          show={open}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="md:absolute md:left-0 md:min-w-56 p-1 md:origin-top-right divide-y divide-gray-100 rounded-b-md bg-white focus:outline-none shadow-xl">
            <div className="px-1 py-1">
              {(href) && (
                <Menu.Item key={'overview'}>
                  {({ active }) => (
                    <Link
                      href={href}
                      className={`${active ? 'bg-navy-600 text-white' : 'text-gray-900'
                        } group flex w-full items-center rounded-md px-2 py-2`}
                    >
                      Overview
                    </Link>
                  )}
                </Menu.Item>
              )}
              {items.map(link => (
                <Menu.Item key={link.id}>
                  {({ active }) => (
                    <Link
                      href={link.url}
                      className={`${active ? 'bg-navy-600 text-white' : 'text-gray-900'
                        } group flex w-full items-center rounded-md px-2 py-2`}
                    >
                      {link.title}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </>
    )}
  </Menu>
}