'use client'
import { createContext, ReactNode, useContext } from "react";
/**
 * @file
 * RedirectProvider context provides
 * redirects to the frontend so they can be issued
 * with query hashes maintained on them.
 * 
 * @see app/[...slug]/Page
 * @see components/app/drupal/PageContent
 */
interface Redirect {
  from: string;
  to: string;
  status: string;
}

const RedirectContext = createContext<Redirect|undefined>(undefined)

export const useRedirectProvider = () => useContext(RedirectContext);
export const RedirectProvider = ({redirect, children}: {
  redirect?: Redirect,
  children: ReactNode
}) => {
  return <RedirectContext.Provider value={redirect}>
    {children}
  </RedirectContext.Provider>
}