'use client'
import { useUserInformation } from "@/contexts/UserProvider"
import { DrupalNode, DrupalTaxonomyTermProduct } from "@/types"
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid"
import { isDefaultSupportUrl, SupportCTA } from "../widget/SupportCTA"
import { sendGTMEvent } from "@next/third-parties/google"
import { Button } from "../base/Button"
import { Transition } from "@headlessui/react"
import { useSearch } from "@/contexts/SearchProvider"

type ProductTerm = Pick<DrupalTaxonomyTermProduct, 'field_support_center_url' | 'name' | 'id'>

export const ContentHelp = ({ resource }: {
  resource?: Pick<DrupalNode, "path"> & {
    field_product?: ProductTerm
    field_products?: ProductTerm[]
  }
}) => {
  const { isAcquian } = useUserInformation()
  const { isOpen, draw, current, search } = useSearch()
  const isVisible = isOpen && draw == 'search'
  const show = !isVisible || (typeof current != 'undefined' && (current.query === undefined || current.query == ""))

  if (isAcquian) {
    return <></>
  }

  const products = [resource?.field_product, ...(resource?.field_products ?? [])].filter(t => t !== undefined) as ProductTerm[]
  const support_urls = products?.filter(p => isDefaultSupportUrl(p.field_support_center_url?.uri))

  return <Transition
        show={show}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
      <div className="my-4 rounded-lg p-5 pb-2 bg-gray-400 max-w-4xl">
      <h3 className="text-blue-600 text-xl font-medium">Did not find what you were looking for?</h3>
      <p className="mb-2">If this content did not answer your questions, try searching or contacting our support team for further assistance.</p>
      <div className="my-2 py-4 transition-colors duration-300 flex gap-2 items-center">
        <div className="grid grid-cols-2 gap-4">
          <Button 
            onClick={() => search('')}
            color="blue"
            primary={true}>
              <span className="flex items-center gap-2"><MagnifyingGlassIcon className="w-4 text-white group-hover:text-white" />Search</span>
          </Button>
          <SupportCTA supportUrls={support_urls} 
          primary={false}
          onClick={() => sendGTMEvent({
            event: 'supportFromContent',
            value: resource?.path.alias
          })} />
        </div>
      </div>
    </div>
  </Transition>
}