'use client'

import React, { useEffect, useState } from "react"
import { search, searchResults } from "@/lib/search/server-actions.server"
import { SURelatedResource } from "./SURelatedResource"
import { useQueryStates } from "nuqs"
import { searchParamsSearch } from "@/lib/searchParams"

export const SURelatedResources = ({query = undefined, filters = undefined, children}: {
  query?: string,
  filters?: string[]
  children?: React.FC<{
    results: searchResults["data"]["result"]["hits"]
  }>
}) =>  {
  const [searchResults, setSearchResults] = useState<searchResults>()
  const [isWorking, setIsWorking] = useState(false)
  const [searchParams] = useQueryStates(searchParamsSearch)

  const searchQuery = query ?? searchParams.searchQuery
  const searchFiltersJson = JSON.stringify(filters ?? searchParams.searchFiltersProducts ?? undefined)
  
  useEffect(() => {
    if (!searchQuery) {
      return
    }

    // Initiate the search (server action).
    search(searchQuery, {products: JSON.parse(searchFiltersJson ?? 'null')}).then((results) => {
      // Set the search results.
      setSearchResults(results)
      setIsWorking(false)
    })

    setIsWorking(true)
    // Prevent further searches to protect the API from search stampedes.
  }, [searchQuery, searchFiltersJson])

  if (searchResults === undefined || searchResults.data.result.hits.length === 0) {
    return <></>
  }

  return <>
    <hr className="border-gray-600" />
    <div className="text-navy-600">
      <h3 className="p-2">Related Resources</h3>
      {searchResults.data.result.hits.map(hit => <SURelatedResource key={hit._id} {...hit} />)}
    </div>
  </>
}